import Typography, { TypographyProps } from '@mui/material/Typography';
import React, { ReactNode } from 'react';

interface BlockTypographyProps extends TypographyProps {
  children: ReactNode;
}

const BlockTypography: React.FC<BlockTypographyProps> = ({ children, ...props }) => {
  return <Typography textAlign="justify" sx={{hyphens: 'manual'}} {...props}>{children}</Typography>;
};

export default BlockTypography;
