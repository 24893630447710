import { AdminUser, AdminUserApi, Booking, Configuration } from "@api";
import { Api, createApi, updateConfig } from "sprungkraft/api/api";
import { create } from "zustand";

export interface Store {
    bookings: Booking[],
    addBooking: (booking: Booking) => void,
    admin_user: AdminUser | null,
    login: (admin_user: AdminUser) => void,
    logout: () => void,
    api: Api
}

export const useStore = create<Store>(set => ({
    bookings: [],
    addBooking: (booking: Booking) => set((state: Store) => {
        const addedBookings = state.bookings;
        addedBookings.push(booking)
        return ({ bookings: addedBookings });
    }),
    admin_user: null,
    login: (admin_user: AdminUser) => set((state: Store) => {
        const newConfig = updateConfig(state.api.configuration, {
            apiKey: admin_user.access_token
        })

        return ({
            admin_user: admin_user,
            api: createApi(newConfig),
        })
    }),
    logout: () => set((state: Store) => {
        const newConfig = updateConfig(state.api.configuration, {
            apiKey: undefined,
        })
        return ({
            admin_user: null,
            api: createApi(newConfig)
        })
    }),
    api: createApi(new Configuration({ basePath: process.env.REACT_APP_API_URL ?? "https://api-anmeldung.sprungkraft-koeln.de/" })),
}));