// tslint:disable
/**
 * Sprungkraft Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    AddCourseOffer,
    ChangeCourseOffer,
    CourseOffer,
} from '../models';

export interface AddCourseOfferRequest {
    addCourseOffer: AddCourseOffer;
}

export interface ChangeCourseOfferRequest {
    courseOfferUuid: string;
    changeCourseOffer: ChangeCourseOffer;
}

export interface DeleteCourseOfferRequest {
    courseOfferUuid: string;
}

export interface GetCourseOfferRequest {
    courseOfferUuid: string;
}

/**
 * no description
 */
export class CourseOfferApi extends BaseAPI {

    /**
     */
    addCourseOffer({ addCourseOffer }: AddCourseOfferRequest): Observable<CourseOffer>
    addCourseOffer({ addCourseOffer }: AddCourseOfferRequest, opts?: OperationOpts): Observable<AjaxResponse<CourseOffer>>
    addCourseOffer({ addCourseOffer }: AddCourseOfferRequest, opts?: OperationOpts): Observable<CourseOffer | AjaxResponse<CourseOffer>> {
        throwIfNullOrUndefined(addCourseOffer, 'addCourseOffer', 'addCourseOffer');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<CourseOffer>({
            url: '/course-offer/',
            method: 'POST',
            headers,
            body: addCourseOffer,
        }, opts?.responseOpts);
    };

    /**
     */
    changeCourseOffer({ courseOfferUuid, changeCourseOffer }: ChangeCourseOfferRequest): Observable<CourseOffer>
    changeCourseOffer({ courseOfferUuid, changeCourseOffer }: ChangeCourseOfferRequest, opts?: OperationOpts): Observable<AjaxResponse<CourseOffer>>
    changeCourseOffer({ courseOfferUuid, changeCourseOffer }: ChangeCourseOfferRequest, opts?: OperationOpts): Observable<CourseOffer | AjaxResponse<CourseOffer>> {
        throwIfNullOrUndefined(courseOfferUuid, 'courseOfferUuid', 'changeCourseOffer');
        throwIfNullOrUndefined(changeCourseOffer, 'changeCourseOffer', 'changeCourseOffer');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<CourseOffer>({
            url: '/course-offer/{course_offer_uuid}'.replace('{course_offer_uuid}', encodeURI(courseOfferUuid)),
            method: 'PATCH',
            headers,
            body: changeCourseOffer,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteCourseOffer({ courseOfferUuid }: DeleteCourseOfferRequest): Observable<void>
    deleteCourseOffer({ courseOfferUuid }: DeleteCourseOfferRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    deleteCourseOffer({ courseOfferUuid }: DeleteCourseOfferRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        throwIfNullOrUndefined(courseOfferUuid, 'courseOfferUuid', 'deleteCourseOffer');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<void>({
            url: '/course-offer/{course_offer_uuid}'.replace('{course_offer_uuid}', encodeURI(courseOfferUuid)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getCourseOffer({ courseOfferUuid }: GetCourseOfferRequest): Observable<CourseOffer>
    getCourseOffer({ courseOfferUuid }: GetCourseOfferRequest, opts?: OperationOpts): Observable<AjaxResponse<CourseOffer>>
    getCourseOffer({ courseOfferUuid }: GetCourseOfferRequest, opts?: OperationOpts): Observable<CourseOffer | AjaxResponse<CourseOffer>> {
        throwIfNullOrUndefined(courseOfferUuid, 'courseOfferUuid', 'getCourseOffer');

        return this.request<CourseOffer>({
            url: '/course-offer/{course_offer_uuid}'.replace('{course_offer_uuid}', encodeURI(courseOfferUuid)),
            method: 'GET',
        }, opts?.responseOpts);
    };

}
