import { AdminUserApi, BookingApi, Configuration, ConfigurationParameters, CourseApi, CourseOfferApi, Title } from "@api";

export interface Api {
    configuration: Configuration,
    courseApi: CourseApi,
    bookingApi: BookingApi,
    courseOfferApi: CourseOfferApi,
    adminUserApi: AdminUserApi,
}

interface UpdateConfigParams {
    basePath?: string,
    accessToken?: string,
}

export const updateConfig = (configuration: Configuration, params: ConfigurationParameters) => (new Configuration(
    {
        basePath: configuration.basePath,
        middleware: configuration.middleware,
        password: configuration.password,
        username: configuration.username,
        apiKey: configuration.apiKey,
        ...params,
    }
))

export const createApi = (configuration: Configuration): Api =>
({
    configuration: configuration,
    adminUserApi: new AdminUserApi(configuration),
    bookingApi: new BookingApi(configuration),
    courseApi: new CourseApi(configuration),
    courseOfferApi: new CourseOfferApi(configuration),
})

export const titleEnumToString = (title: Title) => {
    switch (title) {
        case Title.Mr:
            return "Herr";
        case Title.Ms:
            return "Frau";
        case Title.Divers:
            return "Divers";
    }
}