import { Alert, Button, Stack } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React from "react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom"

interface BasePageProps {
    children: ReactNode,
    showLogoutButton?: boolean

}

const defaultProps = {
    showLogoutButton: true
}

const AdminPage = (props: BasePageProps) => {
    const filledProps = { ...defaultProps, ...props }
    const navigate = useNavigate();

    const goToLogout = () => navigate('/admin/logout')

    const showLogoutButton = filledProps.showLogoutButton;

    return (<Stack direction='column' alignItems="stretch" padding={2}>
        {showLogoutButton}
        <Stack direction="row" justifyContent="space-between">
            <Button onClick={() => navigate(-1)} startIcon={<ArrowBackIosNewIcon />}>Zurück</Button>
            {showLogoutButton && <Button onClick={goToLogout} endIcon={<LogoutIcon />}>Abmelden</Button>}
        </Stack>
        {filledProps.children}
    </Stack>)

}

export default AdminPage