import { Box, Typography, TypographyProps } from "@mui/material";
import React from "react";


const RequiredTypography = ({ children, ...props }: TypographyProps) => {
    return (<Typography {...props}>
        <Box component="span">
            {children}
        </Box>
        <Box component="span" color="secondary.main">
            *
        </Box>
    </Typography>);
}

export default RequiredTypography;