import { Alert, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { parseError } from "sprungkraft/helper/helper_functions";
import { useStore } from "sprungkraft/state/state";
import AdminPage from "sprungkraft/widgets/admin_page";
import LoadingWidget from "sprungkraft/widgets/loading_widget";
import { bool, object, string } from "yup";

interface Values {
    title: string;
    can_swim: boolean;
}


const validationSchema = object<Values>({
    title: string().required("Ausfüllen"),
    can_swim: bool().required("Auswählen"),
})

const CourseAdd = () => {
    const [error, setError] = useState<string | null>(null);
    const { api: { courseApi } } = useStore();
    const navigate = useNavigate();

    const handleAdd = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        courseApi.addCourse({
            addCourse: {
                title: values.title,
                can_swim: values.can_swim,
            }
        }).subscribe({
            next: (course) => {
                setSubmitting(false)
                navigate(-1)
            },
            error: (err) => {
                setSubmitting(false)
                setError(parseError(err))
            }
        })
    }
    return (<AdminPage>
        <Formik
            initialValues={{
                title: '',
                can_swim: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleAdd}
        >{formik =>
            <Form>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h6" >Kurs erstellen</Typography>
                    <Field
                        name="title"
                        label="Titel"
                        helperText="Der Titel des Kurses"
                        component={TextField} />
                    <Field component={CheckboxWithLabel} Label={{ label: 'Schwimmfähigkeit abfragen' }} type="checkbox" name="can_swim" />
                    {error && <Alert severity="error">{error}</Alert>}
                    {formik.isSubmitting && <LoadingWidget />}
                    <Button variant="contained" type="submit">Kurs Erstellen</Button>
                </Stack>
            </Form>
            }
        </Formik>
    </AdminPage>)
}

export default CourseAdd;