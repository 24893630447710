import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useStore } from "sprungkraft/state/state"

interface ProtectedRouteProps {
    child: JSX.Element
}

const ProtectedRoute = ({ child }: ProtectedRouteProps) => {
    const { admin_user } = useStore();

    if (admin_user) {
        return child;

    } else {
        return <Navigate to="/admin/login" />
    }
}

export default ProtectedRoute;