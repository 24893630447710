import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "sprungkraft/state/state";
import BlockTypography from "sprungkraft/widgets/block_typograpy";

const ConfirmPage = () => {
    const { uuid } = useParams()
    const { bookings } = useStore();
    const navigate = useNavigate();

    const booking = bookings.find((value) => value.uuid == uuid);
    if (booking == undefined) {
        // TODO: Error handling
        return (<p>Error</p>);
    }

    const course_details = [
        { name: "Kurs-Nr.", value: booking.course_offer.course_number },
        { name: "Wochentage und Zeiten", value: booking.course_offer.weekday_time_information },
        { name: "Termine", value: booking.course_offer.dates_information },
        { name: "Kosten", value: booking.course_offer.costs_information },
    ]

    const account_details = [
        { name: "Kontoinhaber", value: "Birgit Kienle" },
        { name: "Bank", value: "Sparkasse KölnBonn" },
        { name: "Kontonummer", value: "1051803706" },
        { name: "BLZ", value: "37050198" },
        { name: "IBAN", value: "DE76 3705 0198 1051 8037 06" },
        { name: "SWIFT (BIC)", value: "COLSDE 33" },
        {
            name: "Verwendungszweck",
            value: `${booking.course_offer.course_number}, ${booking.participant.name} ${booking.participant.surname}`
        },
    ]
    return (<React.Fragment>
        <Paper sx={{ p: { xs: 2, md: 3 }, my: { xs: 3, md: 6 } }}>
            <Typography variant="h3" gutterBottom>
                Erfolgreich angemeldet!
            </Typography>
            <BlockTypography variant="body1" paragraph>
                Sie haben sich zu folgendem Kurs erfolgreich angemeldet:
            </BlockTypography>
            <Grid container sx={{ mt: 2, mb: 2 }}>
                {course_details.map(({ name, value }) => [
                    (<Grid item xs={12} sm={4}>
                        <Typography variant="body2">
                            {name}
                        </Typography>
                    </Grid>),
                    (<Grid item xs={12} sm={8}>
                        <Typography variant="body1">
                            {value}
                        </Typography>
                    </Grid>)
                ]).flat()}
            </Grid>
            <BlockTypography variant="body1" paragraph> 
                Bitte drucken Sie sich die Online-Anmeldung,
                die Sie per E-Mail erreicht, aus und bewahren Sie sie gut auf,
                denn diese automatisierte Online-Anmeldung dient allen Teilnehmern der Aquakurse als Eintrittsbescheinigung ins Stadionbad Köln-Müngersdorf, jedoch nur für die Zeit des gebuchten Aquakurses. Bitte die Online-Anmeldebestätigung stets an der Schwimmbadkasse vorzeigen. Viel Spaß beim Training!
            </BlockTypography>
            <Typography variant="h6">
                Kursgebühr bezahlen
            </Typography>
            <BlockTypography variant="body1">
                Bitte überweisen Sie die Kursgebühr an folgendes Konto und
                nennen Sie dabei im Verwendungszweck unbedingt die Kurs-Nr. und den 
                vollständigen Namen des Teilnehmenden.
            </BlockTypography>
            <Grid container sx={{ mt: 2, mb: 2 }}>
                {account_details.map(({ name, value }) => [
                    (<Grid item xs={12} sm={4}>
                        <Typography variant="body2">
                            {name}
                        </Typography>
                    </Grid>),
                    (<Grid item xs={12} sm={8}>
                        <Typography variant="body1">
                            {value}
                        </Typography>
                    </Grid>)
                ]).flat()}
            </Grid>
            <BlockTypography variant="body1">
                Sollte der Kurs nicht zustande kommen oder ausgebucht sein,
                werden Sie noch vor Kursstart informiert und die überwiesene
                Kursgebühr wird umgehend auf Ihr Konto zurückerstattet.
            </BlockTypography>
            <Button sx={{ mt: 2 }} onClick={() => navigate('/')}>Zurück zum Anfang</Button>
        </Paper>
    </React.Fragment>);
};

export default ConfirmPage;