import { Course, CourseOffer } from "@api";
import { Alert, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Link, Radio, RadioGroup, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { parseError } from "sprungkraft/helper/helper_functions";
import { useStore } from "sprungkraft/state/state";
import CoursesList from "./courses_list";
import CoursesOfferList from "./course_offer_list";
import LoadingWidget from "sprungkraft/widgets/loading_widget";
import RequiredFieldInfoText from "sprungkraft/widgets/required_field_info_text";
import RequiredTypography from "sprungkraft/widgets/required_typography";
import BlockTypography from "sprungkraft/widgets/block_typograpy";

export interface CourseValues {
    course: Course,
    courseOffer: CourseOffer,
    acceptCanSwim: boolean,
}

export interface CourseFormProps {
    formik: any
}

const CourseForm = ({ formik }: CourseFormProps) => {
    const [courses, setCourses] = useState<Course[] | null>(null);
    const [error, setError] = useState<String | null>(null);
    const { api: { courseApi } } = useStore()

    useEffect(() => {
        const subscription = courseApi.getActiveCourses().subscribe({ next: setCourses, error: (e) => setError(parseError(e)) });

        return () => { subscription.unsubscribe(); };
    }, []);

    return (<React.Fragment>
        <Typography variant="h6">Kurse</Typography>
        <Box sx={{ mb: 2 }}>
            <Typography variant="caption">
                <Link target="_blank" href="https://www.sprungkraft-koeln.de/haeufige-fragen.html">Erklärung zu den Kursen - bitte anklicken!</Link>
            </Typography>

        </Box>
        {error ?
            <Alert onClose={() => setError(null)} severity="error" sx={{ mb: 2 }}>
                {error}
            </Alert> : (!courses && <LoadingWidget />)}
        <Grid container sx={{ mb: 2 }} spacing={1}>
            <Grid item xs={12}>
                {courses && <CoursesList courses={courses} formik={formik} />}
            </Grid>
            {formik.values.course && (
                formik.values.course?.can_swim && <Grid item xs={12} >
                    <FormControl
                        error={Boolean(formik.errors.acceptCanSwim)}>
                        <FormControlLabel
                            sx={{ alignItems: "flex-start" }}
                            label={<RequiredTypography> Hiermit versichere ich, dass bei mir die Schwimmfähigkeit ohne Hilfsmittel auch im tiefen Wasser vorhanden ist.</RequiredTypography>}
                            name="acceptCanSwim"
                            onChange={formik.handleChange}
                            control={<Checkbox checked={formik.values.acceptCanSwim} />}
                        />
                        {formik.errors.acceptCanSwim && <FormHelperText>{formik.errors.acceptCanSwim}</FormHelperText>}
                    </FormControl>
                </Grid>
            )}
            <Grid item xs={12}>
                {formik.values.course && <CoursesOfferList course={formik.values.course} formik={formik} />}
            </Grid>
            <Grid item xs={12}>
                <BlockTypography variant="body1">
                    Sollten Sie Interesse haben,
                    in einen laufenden Kurs einzusteigen oder sich versehentlich angemeldet haben,
                    kontaktieren Sie uns bitte umgehend über <Link href="mailto:info@sprungkraft-koeln.de">info@sprungkraft-koeln.de</Link>.
                </BlockTypography>
            </Grid>
            <Grid item xs={12}>
                <RequiredFieldInfoText />
            </Grid>
        </Grid>
    </React.Fragment>);
};



export default CourseForm;