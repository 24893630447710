// tslint:disable
/**
 * Sprungkraft Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    AddCourse,
    ChangeCourse,
    Course,
} from '../models';

export interface AddCourseRequest {
    addCourse: AddCourse;
}

export interface ChangeCourseRequest {
    courseUuid: string;
    changeCourse: ChangeCourse;
}

export interface DeleteCourseRequest {
    courseUuid: string;
}

/**
 * no description
 */
export class CourseApi extends BaseAPI {

    /**
     */
    addCourse({ addCourse }: AddCourseRequest): Observable<Course>
    addCourse({ addCourse }: AddCourseRequest, opts?: OperationOpts): Observable<AjaxResponse<Course>>
    addCourse({ addCourse }: AddCourseRequest, opts?: OperationOpts): Observable<Course | AjaxResponse<Course>> {
        throwIfNullOrUndefined(addCourse, 'addCourse', 'addCourse');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<Course>({
            url: '/course/',
            method: 'POST',
            headers,
            body: addCourse,
        }, opts?.responseOpts);
    };

    /**
     */
    changeCourse({ courseUuid, changeCourse }: ChangeCourseRequest): Observable<Course>
    changeCourse({ courseUuid, changeCourse }: ChangeCourseRequest, opts?: OperationOpts): Observable<AjaxResponse<Course>>
    changeCourse({ courseUuid, changeCourse }: ChangeCourseRequest, opts?: OperationOpts): Observable<Course | AjaxResponse<Course>> {
        throwIfNullOrUndefined(courseUuid, 'courseUuid', 'changeCourse');
        throwIfNullOrUndefined(changeCourse, 'changeCourse', 'changeCourse');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<Course>({
            url: '/course/{course_uuid}'.replace('{course_uuid}', encodeURI(courseUuid)),
            method: 'PATCH',
            headers,
            body: changeCourse,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteCourse({ courseUuid }: DeleteCourseRequest): Observable<void>
    deleteCourse({ courseUuid }: DeleteCourseRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    deleteCourse({ courseUuid }: DeleteCourseRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        throwIfNullOrUndefined(courseUuid, 'courseUuid', 'deleteCourse');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<void>({
            url: '/course/{course_uuid}'.replace('{course_uuid}', encodeURI(courseUuid)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getActiveCourses(): Observable<Array<Course>>
    getActiveCourses(opts?: OperationOpts): Observable<AjaxResponse<Array<Course>>>
    getActiveCourses(opts?: OperationOpts): Observable<Array<Course> | AjaxResponse<Array<Course>>> {
        return this.request<Array<Course>>({
            url: '/course/active',
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    getCourses(): Observable<Array<Course>>
    getCourses(opts?: OperationOpts): Observable<AjaxResponse<Array<Course>>>
    getCourses(opts?: OperationOpts): Observable<Array<Course> | AjaxResponse<Array<Course>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<Array<Course>>({
            url: '/course/',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}
