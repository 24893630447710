import React from 'react';
import Logo from "assets/logo.jpg";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ProtectedRoute from 'sprungkraft/widgets/protected_route';
import AdminDashboard from '../admin/admin_dashboard';
import AdminBookings from '../admin/bookings';
import CourseAdd from '../admin/course_add';
import AdminCourseList from '../admin/course_list';
import AdminLogin from '../admin/login';
import AdminLogout from '../admin/logout';
import OfferAdd from '../admin/offer_add';
import OfferEdit from '../admin/offer_edit';
import ConfirmPage from '../confirm';
import InputForm from '../sign_up';
import CourseEdit from '../admin/course_edit';
import { Box, Container, Grid, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdminBookingDetails from '../admin/booking_details';

const router = createBrowserRouter([
    {
        path: '/',
        element: <InputForm />
    },
    {
        path: '/confirm/:uuid',
        element: <ConfirmPage />
    },
    {
        path: '/admin',
        element: <ProtectedRoute child={<AdminDashboard />} />,
    },
    {
        path: '/admin/login',
        element: <AdminLogin />,
    },
    {
        path: '/admin/logout',
        element: <AdminLogout />,
    },
    {
        path: '/admin/course',
        element: <ProtectedRoute child={<AdminCourseList />} />,
    },
    {
        path: '/admin/course/:uuid',
        element: <ProtectedRoute child={<CourseEdit />} />
    },
    {
        path: '/admin/booking/:uuid',
        element: <ProtectedRoute child={<AdminBookingDetails />} />
    },
    {
        path: '/admin/course-add',
        element: <ProtectedRoute child={<CourseAdd />} />
    },
    {
        path: '/admin/course/:uuid/add-offer',
        element: <ProtectedRoute child={<OfferAdd />} />
    },
    {
        path: '/admin/course/:courseUUID/:offerUUID/edit',
        element: <ProtectedRoute child={<OfferEdit />} />
    },
    {
        path: '/admin/bookings',
        element: <ProtectedRoute child={<AdminBookings />} />,
    },
])

const MainPage = () => {
    return (<Stack direction="column" sx={{ pt: 1 }}>
        <Grid container justifyContent="center">
            <a href="https://sprungkraft-koeln.de" target="_blank">
            <Box component="img" src={Logo} sx={{ maxWidth: { xs: 250 } }} />
            </a>
        </Grid>
        <RouterProvider router={router} />
    </Stack>);
}

export default MainPage;