import { Booking, Course, CourseOffer } from "@api";
import { Alert, Box, Button, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { parseError } from "sprungkraft/helper/helper_functions";
import { useStore } from "sprungkraft/state/state";

export interface MoveBookingToCourseWidgetProps {
    booking: Booking;
    onRefresh: () => void;
}

const MoveBookingToCourseWidget = ({ booking, onRefresh }: MoveBookingToCourseWidgetProps) => {
    const [_booking, setBooking] = useState<Booking>(booking);
    const [error, setError] = useState<string | null>(null);
    const [courses, setCourses] = useState<Course[] | null>(null);
    const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
    const [selectedOffer, setSelectedOffer] = useState<CourseOffer | null>(null);
    const { api: { courseApi, bookingApi } } = useStore();

    useEffect(() => {
        const sub = courseApi.getCourses().subscribe(setCourses);
        return () => { sub.unsubscribe(); };
    }, [])

    const moveToCourseOffer = async () => {
        if (!selectedCourse || !selectedOffer) {
            return;
        }

        if (!window.confirm("Buchung wirklich verschieben?")) { return; }
        bookingApi.moveBookingToCourse({ uuid: _booking.uuid, moveBookingToCourse: { course_offer_uuid: selectedOffer.uuid } }).subscribe({
            next: onRefresh,
            error: (err) => setError(parseError(err))
        })
    }

    return <Box>
        Hier können Sie die Buchung in einen anderen Kurs verschieben.

        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <Select
                    label="Kurs"
                    labelId="course-select-label"
                    value={selectedCourse?.uuid ?? ''}
                    fullWidth>
                    {courses?.map(course => <MenuItem
                        onClick={() => setSelectedCourse(course)}
                        value={course.uuid}>{course.title}</MenuItem>)}
                </Select>
            </Grid>
            <Grid item xs={12} md={6}>
                <Select
                    fullWidth
                    value={selectedOffer?.uuid ?? ''}
                    labelId="offer-select-label"
                    disabled={!selectedCourse}
                    label="Angebot">
                    {selectedCourse?.offers.map(offer => <MenuItem onClick={() => setSelectedOffer(offer)}
                        value={offer.uuid}>{offer.weekday_time_information}</MenuItem>)}
                </Select>
            </Grid>
            {selectedOffer && <>
                <Grid item xs={12} md={12}>
                    Ausgewählter Kurs: <b>{selectedCourse?.title}</b><br />
                    Ausgewähltes Angebot:
                    <ul>
                        <li>Wochentag: {selectedOffer.weekday_time_information}</li>
                        <li>Termine: {selectedOffer.dates_information}</li>
                        <li>Kosten: {selectedOffer.costs_information}</li>
                        <li>Ort: {selectedOffer.place_information}</li>
                        <li>Buchungen: {selectedOffer.num_bookings}</li>
                        <li>Anzahl Plätze: {selectedOffer.num_slots}</li>
                    </ul>
                </Grid>
                Sobald Sie auf "Verschieben" klicken, 
                wird die Buchung in den ausgewählten Kurs verschoben und der Teilnehmer 
                erhält eine neue Teilnahmebestätigung per E-Mail.
                {error && <Alert severity="error">{error}</Alert>}
                <Grid item xs={12} md={12}>
                    <Button onClick={moveToCourseOffer}>Buchung Verschieben</Button>
                </Grid>
            </>
            }
        </Grid></Box>;
}

export default MoveBookingToCourseWidget;