import { CourseOffer } from "@api";
import { DeleteForever } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import { Form, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { parseError } from "sprungkraft/helper/helper_functions";
import { useStore } from "sprungkraft/state/state";
import AdminPage from "sprungkraft/widgets/admin_page";
import CourseOfferForm, { CourseOfferValues } from "sprungkraft/widgets/course_offer_form";
import LoadingWidget from "sprungkraft/widgets/loading_widget";

const OfferEditData = ({ offer }: { offer: CourseOffer }) => {
    const [error, setError] = useState<string | null>(null);
    const { api: { courseOfferApi } } = useStore();

    const handleAdd = (values: CourseOfferValues, { setSubmitting }: FormikHelpers<CourseOfferValues>) => {
        setError(null);
        courseOfferApi.changeCourseOffer({
            courseOfferUuid: offer.uuid,
            changeCourseOffer: {
                enabled: values.enabled,
                course_number: values.course_number,
                num_slots: values.num_slots,
                official_course_id: values.official_course_id,
                costs_information: values.costs_information,
                dates_information: values.dates_information,
                place_information: values.place_information,
                weekday_time_information: values.weekday_time_information
            }
        }).subscribe({
            next: (course_offer) => {
                setSubmitting(false)
            }, error: (err) => {
                setSubmitting(false)
                setError(parseError(err))
            }
        })
    }

    return (
        <CourseOfferForm
            error={error}
            onSubmit={handleAdd}
            initialValues={{
                enabled: offer.enabled,
                course_number: offer.course_number,
                official_course_id: offer.official_course_id,
                num_slots: offer.num_slots,
                costs_information: offer.costs_information,
                dates_information: offer.dates_information,
                weekday_time_information: offer.weekday_time_information,
                place_information: offer.place_information,
            }}
            submit_label="Änderungen Speichern"
        />
    );
}

const OfferEdit = () => {
    const { offerUUID } = useParams();
    const navigate = useNavigate();
    const [offer, setOffer] = useState<CourseOffer | null>(null);
    const { api: { courseOfferApi } } = useStore()

    useEffect(() => {
        courseOfferApi.getCourseOffer({ courseOfferUuid: offerUUID! }).subscribe({ next: setOffer });
    }, [])

    const handleOnDelete = () => {
        if (window.confirm("Angebot wirklich löschen?")) {
            courseOfferApi.deleteCourseOffer({
                courseOfferUuid: offerUUID!
            }).subscribe(() => navigate(-1))
        }
    }

    return (<AdminPage>
        <Typography variant="h6">Kursangebot ändern</Typography>
        {offer ? <OfferEditData offer={offer} /> : <LoadingWidget />}

        <Divider sx={{ mt: 2, mb: 2 }} />
        <Button color="error" onClick={handleOnDelete}><DeleteForever />Angebot löschen</Button>
    </AdminPage>);
}

export default OfferEdit;