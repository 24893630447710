import { Box, Link, Typography } from "@mui/material";
import React from "react";
import BlockTypography from "./block_typograpy";

const RequiredFieldInfoText = () => 
    <BlockTypography variant="caption" display="block">
        Mit <Box component="span" color="secondary.main">*</Box>{" "}
        markierte Zeilen sind Pflichtfelder,
        die zum Informationsaustausch von uns mit Ihnen dienen.
        Jederzeit können diese Daten nach Kursende auf
        schriftlichen Teilnehmerwunsch gelöscht werden.
        Die Daten gehen nicht an Dritte.
        Es gilt die <Link target="_blank" href="https://www.sprungkraft-koeln.de/datenschutz.html">Datenschutzerklärung</Link> auf www.sprungkraft-koeln.de!
    </BlockTypography>

export default RequiredFieldInfoText;