import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from "@mui/material";
import { Field, Form, Formik, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import React, { } from "react";
import LoadingWidget from "sprungkraft/widgets/loading_widget";
import { boolean, object, string } from "yup";

export interface CourseOfferValues {
    enabled: boolean,
    course_number: string;
    official_course_id: string;
    num_slots: number;
    dates_information: string;
    place_information: string;
    weekday_time_information: string;
    costs_information: string;
}
const validationSchema = object<CourseOfferValues>({
    enabled: boolean().required("Ausfüllen"),
    course_number: string().required("Ausfüllen"),
    num_slots: string().required("Ausfüllen"),
    official_course_id: string().required('Ausfüllen'),
    dates_information: string().required("Ausfüllen"),
    place_information: string().required("Ausfüllen"),
    weekday_time_information: string().required("Ausfüllen"),
    costs_information: string().required("Ausfüllen")
})

export interface CourseOfferFormProps {
    error: string | null;
    submit_label: string;
    onSubmit: any;
    initialValues: CourseOfferValues;
}

const CourseOfferForm = ({ error, submit_label, onSubmit: onSubmit, initialValues }: CourseOfferFormProps) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >{formik =>
            <Form>
                <Stack direction="column" spacing={1}>
                    <FormControl
                        error={Boolean(formik.errors.enabled)}>
                        <FormControlLabel
                            label="Sichtbar"
                            name="enabled"
                            onChange={formik.handleChange}
                            control={<Checkbox checked={formik.values.enabled} />}
                        />
                        {formik.errors.enabled && <FormHelperText>{formik.errors.enabled}</FormHelperText>}
                    </FormControl>
                    <Field
                        name="course_number"
                        label="Kurs-Nr."
                        component={TextField}
                    />
                    <Field
                        name="official_course_id"
                        label="Kurs-ID"
                        component={TextField}
                    />
                    <Field
                        name="weekday_time_information"
                        label="Wochentag und Uhrzeit"
                        helperText="z.B. Mittwochs 17.00 - 17.55 Uhr"
                        component={TextField}
                    />
                    <Field
                        name="place_information"
                        label="Ort"
                        helperText="z.B. Tulpenweg 2-4 Sporthalle der Gold Krämer Stiftung"
                        component={TextField}
                    />
                    <Field
                        name="dates_information"
                        label="Termine"
                        helperText="z.B. 22.3. - 7.6.2023; kein Kurs am 12.4.; (8x)"
                        component={TextField}
                    />
                    <Field
                        name="costs_information"
                        label="Kosten"
                        helperText="z.B. 133,- Euro"
                        component={TextField}
                    />
                    <Field
                        name="num_slots"
                        label="Anzahl Plätze"
                        helperText="z.B. 10"
                        inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                        component={TextField}
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    {formik.isSubmitting && <LoadingWidget />}
                    <Button variant="contained" type="submit">{submit_label}</Button>
                </Stack>
            </Form>
            }</Formik>);
}

export default CourseOfferForm;

