import { Typography } from "@mui/material"
import { FormikHelpers } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { parseError } from "sprungkraft/helper/helper_functions";
import { useStore } from "sprungkraft/state/state";
import AdminPage from "sprungkraft/widgets/admin_page";
import CourseOfferForm, { CourseOfferValues } from "sprungkraft/widgets/course_offer_form";

const OfferAdd = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const { api: { courseOfferApi } } = useStore()

    const handleAdd = (values: CourseOfferValues, { setSubmitting }: FormikHelpers<CourseOfferValues>) => {
        courseOfferApi.addCourseOffer({
            addCourseOffer: {
                enabled: false,
                course_number: values.course_number,
                course_uuid: uuid!,
                num_slots: values.num_slots,
                official_course_id: values.official_course_id,
                dates_information: values.dates_information,
                place_information: values.place_information,
                weekday_time_information: values.weekday_time_information,
                costs_information: values.costs_information,
            }
        }).subscribe({
            next: (course_offer) => {
                setSubmitting(false)
                navigate(-1)
            }, error: (err) => {
                setSubmitting(false)
                setError(parseError(err))
            }
        })
    }
    return (<AdminPage>
        <Typography variant="h6">Kursangebot erstellen</Typography>
        <CourseOfferForm
            initialValues={{
                enabled: false,
                course_number: '',
                official_course_id: '',
                num_slots: 20,
                dates_information: '',
                place_information: '',
                weekday_time_information: '',
                costs_information: '',
            }}
            onSubmit={handleAdd}
            submit_label="Erstellen"
            error={error} />
    </AdminPage>)
}

export default OfferAdd;