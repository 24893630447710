import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
    const navigate = useNavigate();

    const onGoToCourses = () => navigate('/admin/course')
    const onGoToBookings = () => navigate('/admin/bookings')
    const onGoToLogout = () => navigate('/admin/logout')

    return (<Stack padding={2} spacing={1}>
        <Typography variant="h5" textAlign="center">Admin Dashboard</Typography>
        <Button variant="contained" size="large" onClick={onGoToCourses}>Kurse verwalten</Button>
        <Button variant="contained" size="large" onClick={onGoToBookings}>Buchungen verwalten</Button>
        <Box >
            <Button variant="outlined" sx={{ mt: 2 }} onClick={onGoToLogout} fullWidth>Abmelden</Button>
        </Box>

    </Stack>)
}

export default AdminDashboard;