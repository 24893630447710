import React, { useState } from 'react';
import { Button, Paper, Typography, Stepper, Step, StepLabel, Box, Alert, AlertTitle, CircularProgress, Stack } from '@mui/material';
import PersonForm from './person_form';
import CourseForm, { CourseValues } from './course_form';
import DetailsForm from './details_form';
import { bool, date, object, string } from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Course, CourseOffer, Title } from '@api';
import { useStore } from 'sprungkraft/state/state';
import { addYears, parseError } from 'sprungkraft/helper/helper_functions';
import LoadingWidget from 'sprungkraft/widgets/loading_widget';

const steps = ['Kurs', 'Person', 'Details'];

const courseValidationSchema = object<CourseValues>({
    course: object().required("Wählen Sie einen Kurs aus"),
    courseOffer: object().required("Wählen Sie ein Kursangebot aus"),
    acceptCanSwim: bool().when('course', {
        is: (course: Course) => course.can_swim,
        then: (field) => field.equals([true], "Sie müssen Schwimmen können, um diesen Kurs zu belegen")
    })
})

const personValidationSchema = object({
    title: string().required("Auswählen"),
    name: string().required("Ausfüllen"),
    surname: string().required("Ausfüllen"),
    street: string().required("Ausfüllen"),
    house_number: string().required("Ausfüllen"),
    zip_code: string().required("Ausfüllen").matches(/^\d{5}$/, "Ungültig"),
    city: string().required("Ausfüllen"),
    phone_number: string().required("Ausfüllen"),
    email: string().required("Ausfüllen")
        .matches(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, "Ungültig"),
    date_of_birth: date().required("Ausfüllen")
        .min(addYears(new Date(), -110), "Kein gültiges Geburtsdatum")
        .max(new Date(), "Das Geburtsdatum liegt in der Zukunft"),

    health_insurance: string().required("Ausfüllen")
})

const detailsValidationSchema = object({
    attendance_certificate: bool().required("Auswählen"),
    newsletter: bool().required('Wählen'),
    information: string(),
    accept_fee: bool().equals([true], "Bitte akzeptieren"),
    privacypolicy: bool().equals([true], "Bitte akzeptieren")
})

const InputForm = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();
    const { addBooking, api: { bookingApi } } = useStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <CourseForm formik={formikCourse} />;
            case 1:
                return <PersonForm formik={formikPerson} />;
            case 2:
                return <DetailsForm formik={formikDetails} />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        setError(null);
        setActiveStep(activeStep + 1);
    };

    const handlePersonFormNext = (values: PersonFormFields) => {
        localStorage.setItem('person', JSON.stringify(values));
        handleNext();
    }

    const handleFormSubmit = async () => {
        const courseOffer = formikCourse.values.courseOffer;
        if (courseOffer == null) {
            // TODO: add error handling
            return;
        }
        const courseOfferUUD = courseOffer.uuid;
        setLoading(true);

        await bookingApi.createBooking({
            bookingRequest: {
                course_offer_uuid: courseOfferUUD,
                details: {
                    certificate_of_participation: formikDetails.values.attendance_certificate!,
                    course_newsletter_subscription: formikDetails.values.newsletter,
                    detailed_information: formikDetails.values.information,
                },
                participant: {
                    birth_date: formikPerson.values.date_of_birth,
                    city: formikPerson.values.city,
                    email: formikPerson.values.email,
                    health_insurance: formikPerson.values.health_insurance,
                    house_number: formikPerson.values.house_number,
                    name: formikPerson.values.name,
                    phone_number: formikPerson.values.phone_number,
                    street: formikPerson.values.street,
                    surname: formikPerson.values.surname,
                    title: formikPerson.values.title!,
                    zip_code: formikPerson.values.zip_code,
                }
            }
        }).subscribe({
            next: (booking) => {
                setLoading(false);
                addBooking(booking);
                navigate(`/confirm/${booking.uuid}`)
            }, error: err => {
                setLoading(false);
                setError(parseError(err));
            }
        });
    }

    const handleSubmit = () => {
        setError(null);
        switch (activeStep) {
            case 0: formikCourse.handleSubmit(); break;
            case 1: formikPerson.handleSubmit(); break;
            case 2: formikDetails.handleSubmit(); break;
        }
    }

    const handleBack = () => {
        setError(null);
        setActiveStep(activeStep - 1);
    };

    interface CourseFormFields {
        course: null | Course,
        courseOffer: null | CourseOffer,
        acceptCanSwim: boolean
    }

    const formikCourse = useFormik<CourseFormFields>({
        initialValues: {
            course: null,
            courseOffer: null,
            acceptCanSwim: false
        },
        validateOnChange: false,
        validationSchema: courseValidationSchema,
        onSubmit: _ => handleNext()
    })

    interface PersonFormFields {
        title: null | Title,
        name: string,
        surname: string,
        street: string,
        house_number: string,
        zip_code: string,
        city: string,
        phone_number: string,
        email: string,
        date_of_birth: string,
        health_insurance: string,
    }

    const getPersonFormInitialValues = () => {
        const personStorage = localStorage.getItem('person')
        if (personStorage) {
            const person = JSON.parse(personStorage);
            
            return {
                title: person.title,
                name: person.name,
                surname: person.surname,
                street: person.street,
                house_number: person.house_number,
                zip_code: person.zip_code,
                city: person.city,
                phone_number: person.phone_number,
                email: person.email,
                date_of_birth: person.date_of_birth,
                health_insurance: person.health_insurance,
            }
        } else {
            return {
                title: null,
                name: '',
                surname: '',
                street: '',
                house_number: '',
                zip_code: '',
                city: '',
                phone_number: '',
                email: '',
                date_of_birth: '',
                health_insurance: ''
            }
        }
    }

    const formikPerson = useFormik<PersonFormFields>({
        initialValues: getPersonFormInitialValues(),
        validateOnChange: false,
        validationSchema: personValidationSchema,
        onSubmit: (values, _) => handlePersonFormNext(values)
    })

    const formikDetails = useFormik({
        initialValues: {
            attendance_certificate: null,
            newsletter: false,
            information: '',
            privacypolicy: false,
            accept_fee: false,
        },
        validateOnChange: false,
        validationSchema: detailsValidationSchema,
        onSubmit: _ => handleFormSubmit()
    })


    return (
        <Paper sx={{ p: { xs: 2, md: 3 }, my: 1 }}>
            <form noValidate autoComplete='off'>
                <Typography component="h1" variant="h4" align="center">
                    Online-Anmeldung
                </Typography>
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <React.Fragment>
                    {getStepContent(activeStep)}
                    {error &&
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }} >
                            <AlertTitle>Es ist Fehler aufgetreten</AlertTitle>
                            {error}
                        </Alert>
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }} >
                        {loading && <LoadingWidget />}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {activeStep !== 0 && (
                            <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                Zurück
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            {activeStep === steps.length - 1 ? 'Jetzt Anmelden' : 'Weiter'}
                        </Button>
                    </Box>
                </React.Fragment>
            </form>
        </Paper>
    );
}
export default InputForm;