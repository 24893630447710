import { Booking } from "@api";
import { DeleteForever } from "@mui/icons-material";
import { Alert, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedDate } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { titleEnumToString } from "sprungkraft/api/api";
import { useStore } from "sprungkraft/state/state";
import AdminPage from "sprungkraft/widgets/admin_page";
import FormattedDateTime from "sprungkraft/widgets/formatted_date_time";
import LoadingWidget from "sprungkraft/widgets/loading_widget";
import MoveBookingToCourseWidget from "./move_booking_to_course";

export interface BookingDetailsProps {
    booking: Booking;
    onRefresh: () => void;
}

enum Progress {
    Idle = "idle",
    Done = "done",
    Loading = "loading",
}
const BookingDetails = ({ booking, onRefresh }: BookingDetailsProps) => {
    const { api: { bookingApi } } = useStore();
    const [confirmationEmailProgress, setConfirmationEmailProgress] = useState<Progress>(Progress.Idle);

    const onResendBookingConfirmation = () => {
        setConfirmationEmailProgress(Progress.Loading);
        bookingApi.sendConfirmationEmail({ uuid: booking.uuid }).subscribe(() => setConfirmationEmailProgress(Progress.Done))
    }

    return <Stack spacing={2}>
        <Box>
            <Typography variant="h6">Buchungsdetails</Typography>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Buchungszeitpunkt</Typography>
                    <Typography><FormattedDateTime value={booking.timestamp} /></Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Kurs</Typography>
                    <Typography>Titel: {booking.course.title}</Typography>
                    <Typography>Wochentag: {booking.course_offer.weekday_time_information}</Typography>
                    <Typography>Termine: {booking.course_offer.dates_information}</Typography>
                </Grid>
            </Grid>
        </Box>
        <Box>
            <Typography variant="h6">Personendetails</Typography>
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Vorname</Typography>
                    <Typography>{booking.participant.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Nachname</Typography>
                    <Typography>{booking.participant.surname}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">E-Mail</Typography>
                    <Typography>{booking.participant.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Telefon</Typography>
                    <Typography>{booking.participant.phone_number}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Adresse</Typography>
                    <Typography>{booking.participant.street} {booking.participant.house_number}</Typography>
                    <Typography>{booking.participant.zip_code} {booking.participant.city}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Geburtsdatum</Typography>
                    <Typography>{booking.participant.birth_date}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Geschlecht</Typography>
                    <Typography>{titleEnumToString(booking.participant.title)}</Typography>
                </Grid >
            </Grid>
        </Box>
        <Stack alignItems="start" spacing={1}>
            <Typography variant="h6">Aktionen</Typography>
            {confirmationEmailProgress == Progress.Done && <Alert severity="success" onClose={() => setConfirmationEmailProgress(Progress.Idle)}>Buchungsbestätigung wurde erneut gesendet.</Alert>}
            <Button disabled={confirmationEmailProgress != Progress.Idle} variant="outlined" onClick={onResendBookingConfirmation}>Buchungsbestätigung erneut senden</Button>
        </Stack>
        <Box>
            <Typography variant="h6">Buchung verschieben</Typography>
            <MoveBookingToCourseWidget booking={booking} onRefresh={onRefresh} />
        </Box>
    </Stack >;
}


const AdminBookingDetails = () => {
    const { uuid } = useParams();
    const [booking, setBooking] = useState<Booking | null>(null);
    const { api: { bookingApi } } = useStore()
    const [effect, setEffect] = useState(false);
    const navigate = useNavigate();

    const updateEffect = () => setEffect(!effect);

    useEffect(() => {
        bookingApi.getBooking({ uuid: uuid! }).subscribe({ next: setBooking });
    }, [effect])

    const handleOnDelete = () => {
        if (window.confirm("Buchung wirklich löschen?")) {
            bookingApi.deleteBooking({ uuid: uuid! }).subscribe(() => navigate(-1))
        }
    }

    return <AdminPage>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>Buchungsdetails </Typography>
        {booking ? <BookingDetails booking={booking} onRefresh={updateEffect} /> : <LoadingWidget />}

        <Divider sx={{ mt: 2, mb: 2 }} />
        <Button color="error" onClick={handleOnDelete}><DeleteForever />Buchung löschen</Button>
    </AdminPage>
}

export default AdminBookingDetails;