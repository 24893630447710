// tslint:disable
/**
 * Sprungkraft Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    AdminUser,
    LoginAdminUsers,
} from '../models';

export interface LoginAdminUserRequest {
    loginAdminUsers: LoginAdminUsers;
}

/**
 * no description
 */
export class AdminUserApi extends BaseAPI {

    /**
     */
    loginAdminUser({ loginAdminUsers }: LoginAdminUserRequest): Observable<AdminUser>
    loginAdminUser({ loginAdminUsers }: LoginAdminUserRequest, opts?: OperationOpts): Observable<AjaxResponse<AdminUser>>
    loginAdminUser({ loginAdminUsers }: LoginAdminUserRequest, opts?: OperationOpts): Observable<AdminUser | AjaxResponse<AdminUser>> {
        throwIfNullOrUndefined(loginAdminUsers, 'loginAdminUsers', 'loginAdminUser');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<AdminUser>({
            url: '/admin_user/login',
            method: 'POST',
            headers,
            body: loginAdminUsers,
        }, opts?.responseOpts);
    };

}
