import { Booking } from "@api";
import { DeleteForever } from "@mui/icons-material";
import { Alert, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { parseError } from "sprungkraft/helper/helper_functions";
import { useStore } from "sprungkraft/state/state";

interface BookingsContentProps {
    bookings: Booking[],
    update: Function
}

const BookingsContent = ({ bookings, update }: BookingsContentProps) => {
    const { api: { bookingApi } } = useStore();
    const [error, setError] = useState<string | null>(null);

    const downloadBlob = (blob: Blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.xlsx');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
    }

    const onExportBookings = (all: boolean) => {
        bookingApi.getBookingsXLSX({ onlyNewOnes: !all }).subscribe({
            next: (blob) => {
                downloadBlob(blob);
            },
            error: (err) => setError(parseError(err))
        })
    }

    const onExportAllBookings = onExportBookings.bind(null, true);
    const onExportNewBookings = onExportBookings.bind(null, false);

    const onDeleteAllBookings = () => {
        if (window.confirm("Alle Buchungen wirklich löschen?")) {
            bookingApi.deleteBookings().subscribe({
                next: () => { update() },
                error: (err) => setError(parseError(err))
            })
        }
    }

    return (<Stack alignItems="flex-start" spacing={1}>
        <Typography>Es sind {bookings.length} Buchungen hinterlegt</Typography>
        <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={onExportNewBookings}>Neue Buchungen exportieren</Button>
            <Button onClick={onExportAllBookings}>Alle Buchungen exportieren</Button>
        </Stack>

        <Divider sx={{ mt: 2, mb: 2 }} />
        <Button onClick={onDeleteAllBookings} color="error"><DeleteForever /> Alle Buchungen löschen</Button>
        {error && <Alert severity="error">{error}</Alert>}
    </Stack>);
}

export default BookingsContent;