import { Booking } from "@api";
import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FormattedDateTime from "sprungkraft/widgets/formatted_date_time";

export interface BookingsListProps {
    bookings: Booking[];
}

const BookingsList = ({ bookings }: BookingsListProps) => {
    const navigate = useNavigate();

    const goToBooking = (booking: Booking) => {
        navigate(`/admin/booking/${booking.uuid}`)
    }


    return <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {bookings.sort((a, b) =>
            a.participant.surname.localeCompare(b.participant.surname)
            || a.participant.name.localeCompare(b.participant.name)
            || a.timestamp.localeCompare(b.timestamp)).map((booking: Booking) => (
                <ListItemButton key={booking.uuid} onClick={() => goToBooking(booking)}>
                    <ListItemText
                        primary={`${booking.participant.surname}, ${booking.participant.name}`}
                        secondary={<FormattedDateTime value={booking.timestamp} />} />
                </ListItemButton>
            ))}
    </List>
}

export default BookingsList;