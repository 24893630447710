import { Course } from "@api";
import { ContentCopy } from "@mui/icons-material";
import { Alert, Box, Button, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useStore } from "sprungkraft/state/state";
import AdminPage from "sprungkraft/widgets/admin_page";
import LoadingWidget from "sprungkraft/widgets/loading_widget";

const AdminCourseList = () => {
    const [courses, setCourses] = useState<Course[] | null>(null);
    const navigate = useNavigate()
    const {api: {courseApi}} = useStore();

    const goToCourse = (course: Course) => {
        navigate(`/admin/course/${course.uuid}`)
    }

    const goToAddCourse = () => {
        navigate('/admin/course-add')
    }

    useEffect(() => {
        const sub = courseApi.getCourses().subscribe(setCourses);
        return () => { sub.unsubscribe(); };
    }, [])

    const coursesListWidget = (courses: Course[]) => {
        if (courses.length == 0) {
            return [<Alert severity="info">Kein Kurs enthalten. Fügen Sie Ihren ersten Kurs hinzu.</Alert>]
        }
        return courses.map(course => (
            <ListItem key={course.uuid} >
                <ListItemButton onClick={() => goToCourse(course)}>
                    <ListItemText primary={course.title} />
                </ListItemButton>
            </ListItem>
        ))
    }

    return (<AdminPage>
        <Typography variant="h6"> Kurse </Typography>
        <List>
            {courses ? coursesListWidget(courses) : <LoadingWidget />}
        </List>
        <Button variant="outlined" onClick={goToAddCourse}>Kurs hinzufügen</Button>
    </AdminPage>)
}

export default AdminCourseList