import { Booking } from "@api";
import { Alert, Box, Button, Card, Divider, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { parseError } from "sprungkraft/helper/helper_functions";
import { useStore } from "sprungkraft/state/state";
import AdminPage from "sprungkraft/widgets/admin_page";
import LoadingWidget from "sprungkraft/widgets/loading_widget";
import BookingsContent from "./bookings_content";
import BookingsList from "./bookings_list";


const AdminBookings = () => {
    const [bookings, setBookings] = useState<Booking[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [effect, setEffect] = useState(0);
    const { api: { bookingApi } } = useStore();

    const updateEffect = () => setEffect(effect + 1);

    useEffect(() => {
        setError(null);
        const request = bookingApi.getBookings().subscribe({
            next: setBookings,
            error: (err) => setError(parseError(err))
        })
        return () => request.unsubscribe();
    }, [])

    return <AdminPage>
        <Box>
            <Typography variant="h6">Buchungen</Typography>
            {(!error && !bookings) && <LoadingWidget />}
            {error && <Alert severity="error">{error}</Alert>}

            {bookings && <BookingsContent bookings={bookings} update={updateEffect} />}
        </Box>
        <Divider sx={{marginTop: 2, marginBottom: 2}}/>
        <Box>
            <Typography variant="h6">Buchungsliste</Typography>
            {bookings && <BookingsList bookings={bookings} />}
        </Box>
    </AdminPage>
}

export default AdminBookings;