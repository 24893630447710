import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Link, Radio, RadioGroup, TextField, Typography, TypographyProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import BlockTypography from "sprungkraft/widgets/block_typograpy";
import RequiredTypography from "sprungkraft/widgets/required_typography";

interface DetailsFormProps {
    formik: any
}

const DetailsForm = ({ formik }: DetailsFormProps) => {

    return (<React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <BlockTypography variant="body2">
                    Ich bin darüber informiert,
                    dass der <b>Kurs keine ärztliche oder kranken&shy;gymnastische Einzel&shy;behandlung ersetzen kann</b>.
                    Falls ich zurzeit in ärztlicher oder kranken&shy;gymnas&shy;tischer Behand&shy;lung bin und Zweifel habe,
                    ob der Kurs für mich geeignet ist, werde ich entsprechenden Rat einholen.
                    Die von mir gemachten Angaben zur Person sind streng vertraulich von der Kurs&shy;leitung zu behandeln.
                    In jedem Fall nehme ich an dem Kurs <b>auf eigene Verantwortung</b> teil.
                    Die u.g. Geschäftsbedingungen erkenne ich als rechts&shy;verbindlich an.
                </BlockTypography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <RequiredTypography variant="h6">
                        Teilnahmebescheinigung für KK-Zuschuss
                    </RequiredTypography>
                    <RadioGroup
                        aria-labelledby="radio-buttons-attendance-certificate"
                        name="attendance_certificate"
                        onChange={formik.handleChange}
                        value={formik.values.attendance_certificate}
                    >
                        <FormControlLabel value={true} control={<Radio />} label={
                            <Typography variant="body1">
                                Ja, ich benötige eine Teilnahmebescheinigung!
                            </Typography>
                        } />
                        <FormControlLabel value={false} control={<Radio />} label={
                            <Typography variant="body1">
                                Nein, ich benötige keine Teilnahmebescheinigung!
                            </Typography>
                        } />
                    </RadioGroup>
                    <FormHelperText error={Boolean(formik.errors.attendance_certificate)}>{formik.errors.attendance_certificate}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="outlined-multiline-flexible"
                    label="Informationen"
                    helperText={formik.errors.information ?? "wie z.B. Bluthochdruck, Diabetes, psych. oder orthopädische Einschränkungen"}
                    name="information"
                    onChange={formik.handleChange}
                    value={formik.values.information}
                    error={Boolean(formik.errors.information)}
                    fullWidth
                    multiline
                    maxRows={4}
                />
            </Grid>
            <Grid item xs={12}>
                <RequiredTypography variant="h6">
                    Kursgebühr
                </RequiredTypography>
                <FormControl
                    error={Boolean(formik.errors.accept_fee)}>
                    <FormControlLabel
                        sx={{ alignItems: "flex-start" }}
                        label={
                            <BlockTypography variant="body2">
                                <b>Das Vertragsverhältnis beginnt mit dem Tag der Online-Anmeldung</b> und die Zahlung der Kursgebühr
                                ist spätestens 14 Tage vor Kursbeginn zu leisten.
                                Mit der Online-Anmeldung melde ich mich verbindlich an und verpflichte mich,
                                die erforderlich Kursgebühr rechtzeitig zu überweisen.
                            </BlockTypography>
                        }
                        name="accept_fee"
                        onChange={formik.handleChange}
                        control={<Checkbox checked={formik.values.accept_fee} />}
                    />
                    {formik.errors.accept_fee && <FormHelperText>{formik.errors.accept_fee}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Kursinformation
                </Typography>
                <FormControlLabel
                    sx={{ alignItems: "flex-start" }}
                    label={
                        <BlockTypography variant="body2">
                            Ich möchte über Kursangebote per E-Mail informiert werden. Dies kann jederzeit schriftlich widerrufen werden.
                        </BlockTypography>}
                    name="newsletter"
                    onChange={formik.handleChange}
                    control={<Checkbox checked={formik.values.newsletter} />}
                />
            </Grid>
            <Grid item xs={12}>
                <RequiredTypography variant="h6">
                    Datenschutz und AGB
                </RequiredTypography>
                <FormControl
                    error={Boolean(formik.errors.privacypolicy)}>
                    <FormControlLabel
                        sx={{ alignItems: "flex-start" }}
                        label={
                            <BlockTypography variant="body2">
                                Ich erkenne die <Link href="https://www.sprungkraft-koeln.de/agb.html" target="_blank">Allgemeine Geschäftsbedingungen (AGB)</Link>{" "}
                                von Sprungkraft Gesundheitstraining & Physiotherapie an und habe die{" "}
                                <Link target="_blank" href="https://www.sprungkraft-koeln.de/datenschutz.html">Datenschutzerklärung</Link> zur Kenntnis genommen.
                            </BlockTypography>}
                        name="privacypolicy"
                        onChange={formik.handleChange}
                        control={<Checkbox checked={formik.values.privacypolicy} />}
                    />
                    {formik.errors.privacypolicy && <FormHelperText>{formik.errors.privacypolicy}</FormHelperText>}
                </FormControl>
            </Grid>
        </Grid>

    </React.Fragment>);
}

export default DetailsForm;