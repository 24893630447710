import React from "react";
import { Navigate } from "react-router-dom";
import { useStore } from "sprungkraft/state/state"

const AdminLogout = () => {
    const {logout} = useStore();

    logout();

    return <Navigate to="/" />
}

export default AdminLogout;