import { Course } from "@api"
import { Alert, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Paper, Typography } from "@mui/material"
import { Field, Form, Formik, FormikHelpers } from "formik"
import { Checkbox, CheckboxWithLabel, TextField } from "formik-mui"
import React, { useState } from "react"
import { parseError } from "sprungkraft/helper/helper_functions"
import { useStore } from "sprungkraft/state/state"
import { bool, object, string } from "yup"

interface CourseDetailsProps {
    course: Course
}

interface Values {
    title: string;
    description: string;
    can_swim: boolean;
}

const validationSchema = object<Values>({
    title: string().required("Ausfüllen"),
    description: string(),
    can_swim: bool().required("Ausfüllen"),
})

const CourseDetails = ({ course }: CourseDetailsProps) => {
    const [error, setError] = useState<String | null>(null);
    const { api: { courseApi } } = useStore();

    const changeCourse = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        setError(null);

        courseApi.changeCourse({
            courseUuid: course.uuid,
            changeCourse: {
                title: values.title,
                description: values.description,
                can_swim: values.can_swim,
            }
        }).subscribe({
            next: (value) => {
                setSubmitting(false);
            }, error: (err) => setError(parseError(err))
        })
    }

    return (<Paper sx={{ padding: 2 }}>
        <Formik
            initialValues={{
                title: course.title,
                description: course.description ?? "",
                can_swim: course.can_swim,
            }}
            validationSchema={validationSchema}
            onSubmit={changeCourse}>
            <Form>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Kursdetails</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            label="Kurstitel"
                            component={TextField}
                            name="title"
                            variant="standard" />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <Field
                            label="Beschreibung"
                            component={TextField}
                            name="description"
                            variant="standard"
                            multiline
                            fullWidth
                        />
        </Grid>*/}
                    <Grid item xs={12}>
                        <Field component={CheckboxWithLabel} Label={{ label: 'Schwimmfähigkeit abfragen' }} type="checkbox" name="can_swim" />
                    </Grid>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Grid item xs={12}>
                        <Button type="submit">Speichern</Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    </Paper>
    )
}

export default CourseDetails;