import { AccessTime, AccessTimeOutlined, InfoOutlined, Place, PlaceOutlined } from "@mui/icons-material";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, Stack, Typography, Button, Box, ButtonBase, styled } from "@mui/material";
import { Course, CourseOffer } from "api";
import React from "react";
import CourseOfferWidget from "sprungkraft/widgets/course_offer_widget";

export interface CourseOfferListProps {
    formik: any;
    course: Course;
}

const CustomButton = styled(Button)(({ theme }) => ({
    textAlign: "start",
    justifyContent: "flex-start",
    borderWidth: "1px",
    borderStyle: "solid",
    textTransform: "none",
    color: "black",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    transition: theme.transitions.create('backgroundColor'),
    width: "100%",
    "&.selected": {
        borderWidth: "2px",
        backgroundColor: theme.palette.primary.light
    },
    "&.disabled": {
        backgroundColor: theme.palette.grey[200],
        borderColor: theme.palette.grey[200],
    },
}));

const CoursesOfferList = ({ formik, course }: CourseOfferListProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const courseOfferUUID = (event.target as HTMLInputElement).value;
        const offer = course.offers.find((v) => v.uuid == courseOfferUUID);
        if (offer) {
            formik.setFieldValue('courseOffer', offer);
        }
    };

    return (<FormControl sx={{ width: "100%" }}>
        <Typography variant="subtitle1">Kursangebot</Typography>
        <Stack spacing={1} >
            {course.offers.map((offer: CourseOffer) => (
                <CustomButton key={offer.uuid} onClick={() => formik.setFieldValue('courseOffer', offer)}
                    fullWidth
                    disabled={offer.is_full}
                    className={`${formik.values.courseOffer?.uuid == offer.uuid ? "selected" : ""} ${offer.is_full ? "disabled" : ""}`}>
                    <Stack sx={{width: '100%'}} direction="column" spacing={1} alignItems="stretch">
                        <CourseOfferWidget offer={offer} />
                        {offer.is_full && <Typography variant="caption" color="error">Dieser Kurs ist leider voll.</Typography>}
                    </Stack>
                </CustomButton>
            ))}
        </Stack>
        <FormHelperText error={Boolean(formik.errors.courseOffer)}>{formik.errors.courseOffer}</FormHelperText>
    </FormControl>);
}

export default CoursesOfferList;
