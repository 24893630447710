import { AjaxError } from "rxjs/ajax";

export const parseError = (err: any) => {
    if (err instanceof AjaxError) {
        const response = err.response;
        if (response != null) {
            if (response.hasOwnProperty('message')) {
                return response['message'];
            } else {
                if (response.code == 500) {
                    return 'Interner Serverfehler aufgetreten. Bitte versuchen Sie es später erneut.';
                } else if (response.code == 401) {
                    return 'Sie sind nicht eingeloggt. Bitte loggen Sie sich ein.';
                } else if (response.code == 403) {
                    return 'Sie haben nicht die nötigen Rechte um diese Aktion auszuführen.';
                } else if (response.code == 404) {
                    return 'Die angeforderte Ressource wurde nicht gefunden.';
                } else if (response.code == 400) {
                    return 'Die Anfrage war fehlerhaft.';
                } else {
                    return `${response.code}: ${JSON.stringify(response, undefined, 2)}`;
                }
            }
        }
    }
    return `Unerwarteter Fehler aufgetreten: ${err.message}`;
}

export const addYears = (date: Date, years: number) => {
    var new_date = new Date(date)
    new_date.setFullYear(date.getFullYear() + years)
    return new_date
}