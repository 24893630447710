import { Course } from "@api"
import { Alert, Box, Button, CircularProgress, Divider, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { parseError } from "sprungkraft/helper/helper_functions"
import { useStore } from "sprungkraft/state/state"
import AdminPage from "sprungkraft/widgets/admin_page"
import CourseDetails from "./course_details"
import CourseOffers from "./course_offers"
import { DeleteForever } from "@mui/icons-material"


const CourseEdit = () => {
    const [course, setCourse] = useState<Course | null>(null);
    const navigate = useNavigate();
    const { uuid } = useParams()
    const [error, setError] = useState<String | null>(null);
    const { api: { courseApi } } = useStore();
    const [effect, setEffect] = useState(0);

    const updateEffect = () => setEffect(effect + 1);

    useEffect(() => {
        courseApi.getCourses().subscribe((courses) => {
            const course = courses.find((course) => course.uuid == uuid)
            if (course == undefined) {
                // TODO: error handling
                return;
            }

            setCourse(course);
        })
    }, [effect])

    const handleOnDelete = () => {
        if (window.confirm("Kurs wirklich löschen?")) {
            courseApi.deleteCourse({
                courseUuid: uuid!
            }).subscribe({
                next: () => navigate(-1),
                error: (err) => setError(parseError(err))
            })
        }
    }

    return (<AdminPage>
        {course ?
            <Stack spacing={2} direction="column">
                <CourseDetails course={course} />
                <CourseOffers course={course} reload={updateEffect}/>
                <Button onClick={handleOnDelete} color="error"><DeleteForever /> Kurs löschen</Button>
            </Stack>
            : <CircularProgress />}
        {error && <Alert severity="error">{error}</Alert>}
    </AdminPage>
    )
}

export default CourseEdit