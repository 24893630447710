import { FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, Alert, Button, Box, Typography } from "@mui/material";
import React from "react";
import { Course } from "api";

export interface CoursesListProps {
    formik: any;
    courses: Course[];
}

const CoursesList = ({ formik, courses }: CoursesListProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const courseUUID = (event.target as HTMLInputElement).value;
        const course = courses.find((v) => v.uuid == courseUUID);
        if (course) {
            formik.setFieldValue('courseOffer', null);
            formik.setFieldValue('course', course);
        }
    };

    if (courses.length == 0) {
        return <Alert severity="info">Aktuell gibt es keine Kurse zu denen Sie sich anmelden können.</Alert>

    } else {
        return (<FormControl>
            <RadioGroup
                aria-labelledby="radio-buttons-courses-label"
                name="courseUUID"
                onChange={handleChange}
                value={formik.values.course?.uuid ?? null}
            >
                {courses.map((course: Course) => (
                    <FormControlLabel key={course.uuid} value={course.uuid} control={<Radio />} label={<Box>
                        {course.title}
                    </Box>} />
                ))}
            </RadioGroup>
            <FormHelperText error={Boolean(formik.errors.course)}>{formik.errors.course}</FormHelperText>
        </FormControl>)
    }
};

export default CoursesList;
