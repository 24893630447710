import './App.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { deDE, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MainPage from './pages/main';
import { IntlProvider } from 'react-intl';

const theme = createTheme({
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#a6117e"
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#a6117e",
                    paddingLeft: 10,

                }
            }
        }
    },
    typography: {
        h3: {
            fontSize: 30,
        },
    },
    palette: {
        primary: {
            main: "#009999b0",
            light: "#00999920"
        },
        secondary: {
            main: "#a6117e"
        }
    }
}, deDE);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container component="main" maxWidth="sm">
                <IntlProvider locale="de-DE" defaultLocale='de-DE'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <React.StrictMode>
                            <MainPage />
                        </React.StrictMode>
                    </LocalizationProvider>
                </IntlProvider>
            </Container>
        </ThemeProvider>
    );
}

export default App;
