import { CourseOffer } from "@api";
import { EuroOutlined, EuroSymbolOutlined, Expand, InfoOutlined, PlaceOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export interface CourseOfferWidgetProps {
    offer: CourseOffer;
}

const CourseOfferWidget = ({ offer }: CourseOfferWidgetProps) => {
    return <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" alignItems="flex-start">
            <Stack direction="row" alignItems="flex-start">
                <Typography variant="subtitle1">
                    {offer.weekday_time_information}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="flex-start">
                <PlaceOutlined sx={{ height: "20px", fontSize: 14, mr: 0.5 }} fontSize="small" />
                <Typography variant="caption" color="text.secondary">
                    {offer.place_information}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="flex-start">
                <InfoOutlined sx={{ height: "20px", fontSize: 14, mr: 0.5 }} fontSize="small" />
                <Typography variant="caption" color="text.secondary">
                    {offer.dates_information}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="flex-start">
            </Stack>
        </Stack>
            <Typography variant="subtitle1" sx={{whiteSpace: 'nowrap'}}>
                {offer.costs_information.replace(RegExp('[Ee]uro'), '€')}
            </Typography>
    </Stack>
}

export default CourseOfferWidget;