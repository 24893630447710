import { Alert, Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { parseError } from "sprungkraft/helper/helper_functions";
import { Store, useStore } from "sprungkraft/state/state";
import AdminPage from "sprungkraft/widgets/admin_page";
import LoadingWidget from "sprungkraft/widgets/loading_widget";
import { object, string } from "yup";

interface Values {
    email: string;
    password: string;
}

const validationSchema = object<Values>({
    email: string().required("Ausfüllen").email("Ungültige E-Mail"),
    password: string().required("Ausfüllen")
});

const AdminLogin = () => {
    const [error, setError] = useState<String | null>(null);
    const { admin_user, login: setAdminUser, api: { adminUserApi } } = useStore();
    const navigate = useNavigate();

    const handleOnLogin = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        adminUserApi.loginAdminUser({
            loginAdminUsers: {
                email: values.email,
                password: values.password
            }
        }).subscribe({
            next: (admin_user) => {
                setSubmitting(false);
                setAdminUser(admin_user)
                navigate("/admin")
            },
            error: (err) => {
                setSubmitting(false);
                setError(parseError(err));
            }
        })
    }

    return (<Stack padding={2} spacing={1}>
        <Box> <Button onClick={() => navigate('/')}>Zurück</Button> </Box>
        <Formik
            initialValues={{
                email: "",
                password: ""
            }}
            validationSchema={validationSchema}
            onSubmit={handleOnLogin}
        >{formik =>
            <Form>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h6">Administrator-Anmeldung</Typography>
                    {admin_user && <Alert severity="info">Sie sind bereits angemeldet.</Alert>}
                    <Field
                        name="email"
                        label="E-Mail"
                        component={TextField}
                    />
                    <Field
                        name="password"
                        label="Passwort"
                        type="password"
                        component={TextField}
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    {formik.isSubmitting && <LoadingWidget />}
                    <Button variant="outlined" type="submit">Anmelden</Button>
                </Stack>
            </Form>
            }
        </Formik>
    </Stack>)
}

export default AdminLogin;