import { Course, CourseOffer } from "@api"
import { ContentCopy } from "@mui/icons-material"
import { Alert, Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Snackbar, Typography } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { parseError } from "sprungkraft/helper/helper_functions"
import { useStore } from "sprungkraft/state/state"
import CourseOfferWidget from "sprungkraft/widgets/course_offer_widget"

interface CourseOffersProps {
    course: Course,
    reload: Function
}

const CourseOffers = ({ course, reload }: CourseOffersProps) => {
    const navigate = useNavigate();
    const { api: { courseOfferApi } } = useStore();
    const [copyError, setCopyError] = useState<string | null>(null);

    const handleAddOffer = () => {
        navigate(`/admin/course/${course.uuid}/add-offer`)
    }


    const handleEditOffer = (offer: CourseOffer) => {
        navigate(`/admin/course/${course.uuid}/${offer.uuid}/edit`)
    }

    const offerPrimary = (offer: CourseOffer) => {
        var title = `${offer.weekday_time_information}, ${offer.place_information}`
        if (!offer.enabled) {
            title += ` (Unsichtbar)`;
        }
        return title;
    }

    const copyOffer = (offer: CourseOffer) => {
        courseOfferApi.addCourseOffer({
            addCourseOffer: {
                course_uuid: course.uuid!,
                enabled: false,
                num_slots: offer.num_slots,
                course_number: `${offer.course_number} (Kopie)`,
                official_course_id: offer.official_course_id,
                weekday_time_information: `${offer.weekday_time_information} (Kopie)`,
                dates_information: offer.dates_information,
                costs_information: offer.costs_information,
                place_information: offer.place_information,
            }
        }).subscribe({
            next: (course_offer) => {
                reload()
            }, error: (err) => {
                setCopyError(parseError(err))
            }
        })

    }

    return (<Paper sx={{ padding: 2 }}>
        <Typography variant="h6">
            Kursangebote
        </Typography>
        <Snackbar
            open={copyError != null}
            autoHideDuration={6000}
            onClose={() => setCopyError(null)}
            message={copyError}
        />
        <List>
            {course.offers.length == 0 && <Alert severity="info">Noch kein Angebot. Fügen Sie ein ersten Angebot hinzu.</Alert>}
            {course.offers.map((offer) =>
                <ListItem key={offer.uuid}
                    secondaryAction={
                        <Box>
                            <IconButton edge="end" aria-label="duplicate" onClick={() => copyOffer(offer)}>
                                <ContentCopy />
                            </IconButton>

                        </Box>
                    }
                >
                    <ListItemButton onClick={() => handleEditOffer(offer)}>
                        <CourseOfferWidget offer={offer} />
                    </ListItemButton>
                </ListItem>
            )}
        </List>
        <Button variant="outlined" onClick={handleAddOffer}>Angebot Hinzufügen</Button>
    </Paper>)
}

export default CourseOffers