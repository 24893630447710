// tslint:disable
/**
 * Sprungkraft Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Booking,
    BookingRequest,
    MoveBookingToCourse,
} from '../models';

export interface CreateBookingRequest {
    bookingRequest: BookingRequest;
}

export interface DeleteBookingRequest {
    uuid: string;
}

export interface GetBookingRequest {
    uuid: string;
}

export interface GetBookingsXLSXRequest {
    onlyNewOnes: boolean;
}

export interface MoveBookingToCourseRequest {
    uuid: string;
    moveBookingToCourse: MoveBookingToCourse;
}

export interface SendConfirmationEmailRequest {
    uuid: string;
}

/**
 * no description
 */
export class BookingApi extends BaseAPI {

    /**
     */
    createBooking({ bookingRequest }: CreateBookingRequest): Observable<Booking>
    createBooking({ bookingRequest }: CreateBookingRequest, opts?: OperationOpts): Observable<AjaxResponse<Booking>>
    createBooking({ bookingRequest }: CreateBookingRequest, opts?: OperationOpts): Observable<Booking | AjaxResponse<Booking>> {
        throwIfNullOrUndefined(bookingRequest, 'bookingRequest', 'createBooking');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Booking>({
            url: '/booking/',
            method: 'POST',
            headers,
            body: bookingRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteBooking({ uuid }: DeleteBookingRequest): Observable<void>
    deleteBooking({ uuid }: DeleteBookingRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    deleteBooking({ uuid }: DeleteBookingRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        throwIfNullOrUndefined(uuid, 'uuid', 'deleteBooking');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<void>({
            url: '/booking/{uuid}'.replace('{uuid}', encodeURI(uuid)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteBookings(): Observable<void>
    deleteBookings(opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    deleteBookings(opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<void>({
            url: '/booking/all',
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getBooking({ uuid }: GetBookingRequest): Observable<Booking>
    getBooking({ uuid }: GetBookingRequest, opts?: OperationOpts): Observable<AjaxResponse<Booking>>
    getBooking({ uuid }: GetBookingRequest, opts?: OperationOpts): Observable<Booking | AjaxResponse<Booking>> {
        throwIfNullOrUndefined(uuid, 'uuid', 'getBooking');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<Booking>({
            url: '/booking/{uuid}'.replace('{uuid}', encodeURI(uuid)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getBookings(): Observable<Array<Booking>>
    getBookings(opts?: OperationOpts): Observable<AjaxResponse<Array<Booking>>>
    getBookings(opts?: OperationOpts): Observable<Array<Booking> | AjaxResponse<Array<Booking>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<Array<Booking>>({
            url: '/booking/all',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getBookingsXLSX({ onlyNewOnes }: GetBookingsXLSXRequest): Observable<Blob>
    getBookingsXLSX({ onlyNewOnes }: GetBookingsXLSXRequest, opts?: OperationOpts): Observable<AjaxResponse<Blob>>
    getBookingsXLSX({ onlyNewOnes }: GetBookingsXLSXRequest, opts?: OperationOpts): Observable<Blob | AjaxResponse<Blob>> {
        throwIfNullOrUndefined(onlyNewOnes, 'onlyNewOnes', 'getBookingsXLSX');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'only_new_ones': onlyNewOnes,
        };

        return this.request<Blob>({
            url: '/booking/export',
            method: 'GET',
            headers,
            query,
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     */
    moveBookingToCourse({ uuid, moveBookingToCourse }: MoveBookingToCourseRequest): Observable<Booking>
    moveBookingToCourse({ uuid, moveBookingToCourse }: MoveBookingToCourseRequest, opts?: OperationOpts): Observable<AjaxResponse<Booking>>
    moveBookingToCourse({ uuid, moveBookingToCourse }: MoveBookingToCourseRequest, opts?: OperationOpts): Observable<Booking | AjaxResponse<Booking>> {
        throwIfNullOrUndefined(uuid, 'uuid', 'moveBookingToCourse');
        throwIfNullOrUndefined(moveBookingToCourse, 'moveBookingToCourse', 'moveBookingToCourse');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<Booking>({
            url: '/booking/{uuid}/move-to-course'.replace('{uuid}', encodeURI(uuid)),
            method: 'POST',
            headers,
            body: moveBookingToCourse,
        }, opts?.responseOpts);
    };

    /**
     */
    sendConfirmationEmail({ uuid }: SendConfirmationEmailRequest): Observable<void>
    sendConfirmationEmail({ uuid }: SendConfirmationEmailRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    sendConfirmationEmail({ uuid }: SendConfirmationEmailRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        throwIfNullOrUndefined(uuid, 'uuid', 'sendConfirmationEmail');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-API-KEY': this.configuration.apiKey('X-API-KEY') }), // defaultAuth authentication
        };

        return this.request<void>({
            url: '/booking/{uuid}/send-confirmation-email'.replace('{uuid}', encodeURI(uuid)),
            method: 'POST',
            headers,
        }, opts?.responseOpts);
    };

}
