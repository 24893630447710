import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";
import { Title } from "api";
import RequiredFieldInfoText from "sprungkraft/widgets/required_field_info_text";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface PersonFormProps {
    formik: any
}

const PersonForm = ({ formik }: PersonFormProps) => {
    const { setFieldValue, handleChange, values, errors } = formik;

    const handleDateChange = (name: string, val: any) =>
        setFieldValue(name, (val != null) ? val.format('YYYY-MM-DD') : null)

    return (<React.Fragment>
        <Typography variant="h6" gutterBottom>Person</Typography>
        <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item xs={12}>
                <Box sx={{ maxWidth: 150 }}>
                    <FormControl fullWidth>
                        <InputLabel required variant="standard" id="person-title">Anrede</InputLabel>
                        <Select
                            variant="standard"
                            labelId="person-title"
                            name="title"
                            value={values.title}
                            label="Anrede"
                            onChange={handleChange}
                            error={Boolean(errors.title)}
                        >
                            <MenuItem value={Title.Mr}>Herr</MenuItem>
                            <MenuItem value={Title.Ms}>Frau</MenuItem>
                            <MenuItem value={Title.Divers}>Divers</MenuItem>
                        </Select>
                        <FormHelperText error={Boolean(errors.title)}>{errors.title}</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    label="Vorname"
                    name="name"
                    autoComplete="given-name"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    label="Nachname"
                    name="surname"
                    autoComplete="family-name"
                    fullWidth
                    value={values.surname}
                    onChange={handleChange}
                    error={Boolean(errors.surname)}
                    helperText={errors.surname}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    required
                    label="Straße"
                    name="street"
                    fullWidth
                    autoComplete="address-line1"
                    value={values.street}
                    onChange={handleChange}
                    error={Boolean(errors.street)}
                    helperText={errors.street}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    label="Hausnummer"
                    name="house_number"
                    autoComplete="address-line2"
                    fullWidth
                    value={values.house_number}
                    onChange={handleChange}
                    error={Boolean(errors.house_number)}
                    helperText={errors.house_number}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    required
                    label="Postleitzahl"
                    name="zip_code"
                    autoComplete="postal-code"
                    fullWidth
                    value={values.zip_code}
                    onChange={handleChange}
                    error={Boolean(errors.zip_code)}
                    helperText={errors.zip_code}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <TextField
                    required
                    label="Stadt"
                    name="city"
                    autoComplete="address-level2"
                    fullWidth
                    value={values.city}
                    onChange={handleChange}
                    error={Boolean(errors.city)}
                    helperText={errors.city}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    label="Telefonnummer"
                    name="phone_number"
                    autoComplete="tel"
                    fullWidth
                    value={values.phone_number}
                    onChange={handleChange}
                    error={Boolean(errors.phone_number)}
                    helperText={errors.phone_number}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    label="E-Mail"
                    name="email"
                    autoComplete="email"
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <DatePicker
                    label="Geburtsdatum"
                    localeText={{
                        fieldYearPlaceholder: () => 'YYYY'
                    }}
                    value={dayjs(values.date_of_birth)}
                    onChange={(val) => handleDateChange('date_of_birth', val)}
                    format="DD.MM.YYYY"
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            fullWidth: true,
                            required: true,
                            error: Boolean(errors.date_of_birth),
                            helperText: errors.date_of_birth,
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    label="Krankenkasse"
                    name="health_insurance"
                    fullWidth
                    value={values.health_insurance}
                    onChange={handleChange}
                    error={Boolean(errors.health_insurance)}
                    helperText={errors.health_insurance}
                    variant="standard"
                />
            </Grid>
        </Grid>
        <RequiredFieldInfoText />
    </React.Fragment>)
};

export default PersonForm;